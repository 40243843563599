<template>
  <div class="header__user" v-click-outside="handleClickOutside">
    <div class="header__user-logo">
      <UserLogo />
    </div>
    <div v-if="user" class="header__user-info">
      <div class="header__user-name">{{ user.name || "User name" }}</div>
      <div class="header__user-role">{{ user.groups[0].name }}</div>
    </div>
    <div @click="handleOpenUserMenu" :class="['header__user-button', userMenu && 'active']">
      <SelectIcon />
    </div>
    <ul v-if="userMenu" class="header__user-list">
      <li @click="handleLogout" class="header__user-list-item">Logout</li>
    </ul>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";

import UserLogo from "../../assets/images/logo/user-logo.svg";
import SelectIcon from "../../assets/images/icons/select-icon-small.svg";
import { getStoredUserInfo } from "../../helpers/userAuth";
export default {
  name: "HeaderName",
  data() {
    return {
      userMenu: false,
      user: null,
    };
  },
  directives: {
    ClickOutside,
  },
  components: {
    UserLogo,
    SelectIcon,
  },
  mounted() {
    if (getStoredUserInfo()) {
      this.user = getStoredUserInfo();
    }
  },
  methods: {
    handleOpenUserMenu() {
      this.userMenu = !this.userMenu;
    },
    handleClickOutside() {
      this.userMenu = false;
    },
    handleLogout() {
      this.$store.dispatch("users/logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__user {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 100px;
  }

  &__user-list {
    width: calc(100% + 11px);
    list-style: none;
    position: absolute;
    top: 120%;
    left: -11px;
    background: $g-color-white;
    z-index: 100001;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid $mainDisabled;
    border-radius: 5px;
  }

  &__user-list-item {
    padding: 5px 15px;
    color: $mainColor;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background: $mainSecondColor;
      color: $g-color-white;
    }
  }

  &__user-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: $mainDisabled;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &__user-info {
    padding-top: 5px;
  }

  &__user-name {
    padding-bottom: 2px;
    color: $g-color-white;
    font-size: 14px;
  }

  &__user-role {
    color: $g-color-white;
    font-size: 12px;
    font-weight: bold;
  }

  &__user-button {
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;

    svg {
      path {
        fill: $g-color-white;
      }
    }

    &.active {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1500px) {
  .header {
    &__user {
      padding-right: 0;
    }
  }
}
</style>
