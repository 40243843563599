<template>
  <div :class="['navigation-menu', menu && 'active']">
    <div :class="['navigation-menu__container']">
      <div class="navigation-menu__header">
        <svg
          @click="handleToggleMenu()"
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path d="M4 18L20 18" stroke="#000000" stroke-width="2" stroke-linecap="round" />
          <path d="M4 12L20 12" stroke="#000000" stroke-width="2" stroke-linecap="round" />
          <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
      <div v-show="menu" class="navigation-menu__links">
        <NavigationMenuLinks />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationMenuLinks from "../molecules/NavigationMenuLinks.vue";
export default {
  components: {
    NavigationMenuLinks,
  },
  data() {
    return {
      menu: true,
    };
  },
  methods: {
    handleToggleMenu() {
      this.menu = !this.menu;
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu {
  min-width: 60px;
  max-width: 60px;
  border-right: 1px solid $mainDisabled;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.2);
  transition: all ease-in-out 0.5s;

  &.active {
    min-width: 210px;
    max-width: 220px;
    transition: all ease-in-out 0.5s;
  }

  &__container {
    position: relative;
    height: 100%;
  }

  &__links {
    position: sticky;
    top: 0;
  }

  &__header {
    height: 60px;
    border-bottom: 1px solid $mainDisabled;
    display: flex;
    align-items: center;

    svg {
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
