<template>
  <div class="main">
    <header class="main__header">
      <Header />
    </header>
    <main class="main__body">
      <MainNavigationMenu />
      <div class="main__content">
        <slot />
      </div>
    </main>
  </div>
</template>

<script>
import MainNavigationMenu from "../components/sections/MainNavigationMenu.vue";
import Header from "../components/layouts/Header.vue";

export default {
  name: "Main",
  props: {
    triggerPushNotification: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MainNavigationMenu,
    Header,
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    min-width: 100%;
  }

  &__body {
    @include flex-row;
    flex-grow: 1;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    background: $secondaryBackground;
  }
}
</style>
