<template>
  <div :class="['main-modal', modalCenter ? 'center' : '']" @click.self="handleCloseModal">
    <div class="modal-container">
      <slot />
      <svg
        @click="handleCloseModal"
        class="close-modal-btn"
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1488 9.00013L18.1578 1.99094C18.6139 1.53502 18.6139 0.797859 18.1578 0.341939C17.7019 -0.11398 16.9647 -0.11398 16.5088 0.341939L9.49959 7.35114L2.49061 0.341939C2.03448 -0.11398 1.29753 -0.11398 0.841611 0.341939C0.385479 0.797859 0.385479 1.53502 0.841611 1.99094L7.85059 9.00013L0.841611 16.0093C0.385479 16.4653 0.385479 17.2024 0.841611 17.6583C1.06882 17.8858 1.36757 18 1.66611 18C1.96465 18 2.26318 17.8858 2.49061 17.6583L9.49959 10.6491L16.5088 17.6583C16.7362 17.8858 17.0348 18 17.3333 18C17.6318 18 17.9304 17.8858 18.1578 17.6583C18.6139 17.2024 18.6139 16.4653 18.1578 16.0093L11.1488 9.00013Z"
          fill="#FFD900"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultModal",
  props: {
    onClose: {
      type: Function,
      default() {
        return {};
      },
    },
    modalCenter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleCloseModal() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss">
.main-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 111500;

  .modal-container {
    display: flex;
    position: absolute;
    background: #007bff;
    bottom: 0;
    width: 100%;
    padding: 12px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.25);
  }

  .close-modal-btn {
    position: absolute;
    right: 5px;
    cursor: pointer;

    path {
      fill: $g-color-white;
    }
  }
}
.main-modal.center {
  background: rgba(0, 0, 0, 0.5);

  .modal-container {
    display: block;
    overflow-y: auto;
    position: absolute;
    background: #fff;
    min-width: 200px;
    max-width: 600px;
    top: 50%;
    left: 50%;
    padding: 25px 30px;
    transform: translate(-50%, -50%);
  }

  .close-modal-btn {
    top: 10px;
    right: 10px;

    path {
      fill: #007bff;
    }
  }
}
</style>
